.event {
    & h1 {
        margin-bottom: 15px;
        font-size: 28px;
    }
    & label {
        margin-bottom: 5px;
        font-size: 16px;
    }
    & input {
        font-size: 16px;
    }
    &__participants {
        display: flex;
        width: 70%;
        margin: auto;
        padding: 5px 10px 5px 10px;
        justify-content: center;
        border-radius: 7px;
        border: solid 1px white;
        background-color: $color-tertiary;
        transform: scale(1);
        &:hover {
            transform: scale(1.05);
            box-shadow: 1px 1px $color-secondary;
        }
        &__link a{
            padding-left: 10px;
            text-decoration: none;
        }
    }
    &__dates{
        margin-top: 15px;
        display: flex;
        justify-content: space-around;
        &__start {
            display: flex;
            &__icon {
                padding-right: 10px;
            }
        }
        &__end {
            display: flex;
            &__icon {
                padding-right: 10px;
            }
        }
    }
}

.loading {
    width: 100%;
    margin: auto;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    &__text {
        width: 100%;
        margin-top: 20px;
        text-align: center;
    }
    &__spinner {
        margin-top: 20px;
    }
}

.addLink {
    margin-top: 10px;
    width: 80%;
}