.header {
    text-align: right;
    margin-bottom: 30px;
    position: relative;
    display: flex;
    margin: auto;
    height: 50px;
    justify-content: space-between;
    align-items: flex-end;
    &__account {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }
    &__link {
        text-decoration: none;
        color: $color-text;
        padding: 5px;
        color: $color-textSpecial;
        padding-right: 5px;
        border: 1px solid $color-text;
        background-color: $color-tertiary;
        width: 100%;
        margin-left: 45%;
        border-top: 0px;
        border-radius: 0px 0px 5px 5px;
        transform: scale(1);
        &:hover {
            color: $color-secondary;
            transform: scale(1.1);
        }
    }
    & span {
        padding-bottom: 10px;
        padding-right: 4px;
        padding-top: 5px;
        background-color: $color-tertiary;
        width: 100%;
        border: 1px solid;
        border-top: 0px;
        border-radius: 0px 0px 5px 5px;
    }
}

nav {
    position: absolute;
    top: 120%;
    right: 2%;
    display: flex;
    width: 60%;
    flex-direction: column;
    align-items: flex-end;
    background-color: $color-primary;
    padding: 5px;
    z-index: 1;
    padding-top: 0;
}

.breadcrumb {
    padding-left: 10px;
    font-style: italic;
    color: $color-textSpecial;
    cursor: pointer;
}