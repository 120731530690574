.collapse {
    border-radius: 10px 10px 0px 0px;
    width: 80%;
    margin: auto;
    margin-top: 25px;
    &__header {
        border-radius: 10px 10px 0px 0px;
        border: 1px solid $color-text;
        display: flex;
        justify-content: space-between;
        padding: 5px;
        background-color: $color-secondary;
        &__icon {
            cursor: pointer;
        }
    }
    &__description {
        padding: 10px 5px 10px 5px;
        text-align: justify;
        border: 1px solid $color-text;
        border-radius: 0px 0px 10px 10px;
        background-color: $color-tertiary;
        & a {
            color: $color-textSpecial;
            font-style: italic;
        }
    }
}