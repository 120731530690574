.rate__form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    &__label {
        width: 60%;
        font-size: 16px;
    }
    & select {
            cursor: pointer;
            background-color: $color-tertiary;
            border: 0px;
            padding: 2px;
            border-radius: 8px;
            width: 100%;
            text-align: center;
            margin: auto;
    }
    &__action{
        display: flex;
        flex-direction: column;
    }
}

#submitRate {
    width: 100%;
    font-size: 14px;
    padding: 5px 10px 5px 10px;
    margin: 0px;
    margin-top: 10px;
}