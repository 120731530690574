.cardAction {
    display: flex;
    margin-right: 24px;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: center;
    height: 240px;
    width: 250px;
    align-items: center;
    background: $color-tertiary;
    border-radius: 32px;
    &__image { 
        background-color: transparent;
    }
    &__text {
        font-weight: 400;
        font-size: 20px;
        text-align: center;
        margin: 7px;
    }
}

.landing{
    color: $color-text;
    margin-top: 0;
    width: 100%;
    background-color: transparent;
    overflow: hidden;
    &__cardsContainer {
        display: flex;
        flex-wrap: nowrap;
        background-color: transparent;
        overflow-x: scroll;
        scroll-behavior: smooth;
        scrollbar-width: none;
        margin-left: 10%;
        width: 100%;
        margin-bottom: 80px;
    }
    & h1 {
        width: 100%;
        font-size: 48px;
        overflow-x: hidden;
        text-align: center;
        margin-top: 0;
        padding-top: 10px;
        margin-bottom: 0;
        color: $color-textSpecial;
    }
    & h2 {
        width: 70%;
        margin: auto;
        padding-bottom: 60px;
        text-align: center;
        font-weight: 400;
        font-size: 20px;
    }
    &__specialWord {
        color: $color-textSpecial;
    }
    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-secondary;
        border-radius: 50px;
        width: 90%;
        margin: auto;
        margin-top: 30px;
        cursor: pointer;
        &__text {
            font-size: 36px;
            font-weight: 400;
            margin-left: 30px;
        }
        &__image {
            padding-top: 10px;
            padding-bottom: 10px;
        }
        &__image.animation{
            animation-name: button-landing-image;
            animation-duration: 2s;
            animation-fill-mode: forwards;
        }
        &__text.animation{
            animation-name: button-landing-text;
            animation-duration: 0.5s;
            animation-fill-mode: forwards;
        }
    }
    &__footer {
        width: 100%;
        position: absolute;
        bottom: 0px;
        z-index: -1;
        height: 33%;
        & img {
            width: 100%;
            height: 100%;
            filter: blur(6px);
        }
    }
    &__phone {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
        font-size: 20px;
        margin: auto;
        &__text {
            text-align: justify;
        }
        &__image {
            margin-top: 50px;
        }
    }
}