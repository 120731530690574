.error {
    position: absolute;
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    top: 30%;
    left: 10%;
    border: 1px solid;
    border-radius: 8px;
    font-size: 18px;
    padding: 10px 5px 10px 5px;
    background-color: $color-primary;
    &__button button {
        cursor: pointer;
        margin-top: 10px;
        padding: 5px 10px 5px 10px;
        border-radius: 5px;
        background-color: $color-secondary;
    }
}