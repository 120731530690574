@keyframes button-landing-image {
    0% {
        transform: translateX(0);
        opacity: 1;
    }
    50% {
        transform: translateX(420%);
        opacity: 1;
    }
    100% {
        transform: translateX(400%);
        opacity: 0;
        display: none;
    }
}

@keyframes button-landing-text {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        display: none;
    }
}