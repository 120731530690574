.spot {
    & h1 {
        font-size: 24px;
        font-weight: 400;
        color: $color-textSpecial;
        width: 90%;
        margin: auto;
        margin-top: 15px;
        margin-bottom: 15px;
    }
    &__header {
        display: flex;
        margin: 10px;
    }
    &__prices {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 50%;
        margin: auto;
        padding: 8px;
        border-radius: 8px;
        border: 1px solid $color-text;
        background-color: $color-tertiary;
        box-shadow: 1px 1px 1px;
    }
    &__indisponible {
        padding-top: 20px;
        color: $color-secondary;
    }
    &__averageRate {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 8px;
        width: 30%;
        margin: auto;
        border-radius: 8px;
        border: 1px solid $color-text;
        background-color: $color-tertiary;
        box-shadow: 1px 1px 1px;
        &__content {
            display: flex;
            &__value {
                margin-left: 8px;
            }
        }
        &__label {
            text-align: center;
            padding-bottom: 2px;
        }
    }
    &__user {
        text-align: center;
        font-style: italic;
        color: $color-textSpecial;
        padding-bottom: 25px;
        padding-top: 10px;
    }
    &__images {
        display: flex;
        flex-direction: row;
        height: 220px;
        align-items: center;
        background-color: $color-tertiary;
        overflow-x: scroll;
        scrollbar-width: none;
        margin-bottom: 5px;
        &__image {
            height: 200px;
            padding: 10px;
            border-radius: 10px;
            & img {
                border: 2px solid $color-text;
                object-fit: fill;
                height: 100%;
                border-radius: 10px;
            }
        }
    }
    &__collapses {
        background-color: $color-tertiary;
        padding: 2px;
        margin-top: 12px;
    }
}

