.cardSpot {
    display: flex;
    flex-direction: column;
    width: 210px;
    margin-top: 15px;
    padding: 5px;
    border-radius: 10px;
    background-color: $color-tertiary;
    border: 1px solid $color-text;
    box-shadow: 1px 1px 2px $color-text;
    transform : scale(1);
    margin-right: 50px;
    cursor: pointer;
    &:hover {
        box-shadow: 4px 4px 2px $color-secondary;
        transform: scale(1.05);
    }
    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        overflow: hidden;
        padding-bottom: 5px;
        background-color: $color-tertiary;
        &__title {
            font-size: 16px;
            height: 40px;
            overflow: hidden;
        }
        &__rate {
            padding-top: 2px;
            padding-right: 3px;
            display: flex;
            font-size: 14px;
            &__icon{
                padding-right: 5px;
                font-size: 12px;
            }
        }
    }
    &__image{
        width: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        margin-top: 0px;
        margin-bottom: 0px;
        // height: 50px;
        & img {
            border-top-left-radius: 85x;
            border-top-right-radius: 5px;
            width: 100%;
            height: 100px;
            object-fit: cover;
        }
    }
    &__location{
        border-bottom: solid 2px $color-tertiary;
        font-weight: 200;
        border-top: 0px;
        font-style: italic;
        overflow:hidden;
        background-color: $color-tertiary;
        font-size: 14px;
        padding: 4px;
        padding-top: 6px;
        padding-bottom: 6px;
        display: flex;
        overflow: hidden;
        justify-content: space-between;
        align-items: center;
        height: 55px;
        &__text {
            text-align: end;
            padding-left: 10px;
        }
        // border-top-left-radius: 4px;
        // border-top-right-radius: 4px;
    }
    &__price{
        border-top: solid 2px $color-tertiary;
        font-weight: 200;
        font-size: 14px;
        color: $color-textSpecial;
        font-weight: 200;
        padding: 4px;
        display: flex;
        justify-content: space-between;
        &__icon {
            align-content: center;
        }
        &__text{
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
    }
    &__user {
        background-color: $color-tertiary;
        padding: 4px;
        font-size: 14px;
        font-weight: 200;
        padding-left: 6px;
        display: flex;
        justify-content: space-between;
        padding-bottom: 6px;
    }
    &__more{
        border: solid 2px $color-tertiary;
        text-align: center;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    &__indisponible {
        background-color: darken($color-secondary,4);
        padding: 2px 5px 2px 5px;
        font-style: italic;
        text-align: center;
        border-radius: 5px;
    }
}

.cardLink {
    text-decoration: none;
}