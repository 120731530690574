.participants {
    &__title {

    }
    &__creator {
        display: flex;
        width: 90%;
        margin: auto;
        justify-content: space-between;
        background-color: $color-tertiary;
        padding: 10px;
        border-radius: 10px;
        border: 1px solid $color-text;
        margin-bottom: 25px;
        &__label {

        }
        &__name {

        }
    }
    & h2 {
        padding: 0;
        margin-bottom: 10px;
    }
    &__container {
        width: 50%;
        margin: auto;
    }
    &__invited {
        text-align: center;
        width: 90%;
        margin: auto;
        background-color: $color-tertiary;
        padding: 10px;
        border-radius: 10px;
        border: 1px solid $color-text;
        height: 200px;
        overflow: scroll;
    }
    &__form {
        & label {
            margin-bottom: 5px;
        }
    }
}

.addName {
    margin-top: 15px;
    width: 50%;
    padding: 5px 10px 5px 10px;
}

#name {
    margin-top: 10px;
}