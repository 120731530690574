.events {
    
    & h2 {
        padding-bottom: 20px;
    }
    &__created {
        max-height: 350px;
        overflow: scroll;
        margin-bottom: 20px;
    }
    &__participated {
        max-height: 350px;
        overflow: scroll;
        margin-bottom: 20px;
    }
    &__addEvent {
        font-size: 20px;
        width: 70%;
        margin-top: 10px;
    }
    &__switch {
        cursor: pointer;
        text-align: center;
        width: 82%;
        padding: 8px 0px 8px 0px;
        border: 1px solid;
        box-shadow: 1px 1px 1px;
        border-radius: 8px;
        background-color: $color-tertiary;
        margin: auto;
        margin-bottom: 30px;
    }
}