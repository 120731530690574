.spots {
    display: flex;
    overflow: scroll;
    scrollbar-width: none;
    width: auto;
    margin-top: 20px;
    margin-left: 0px;
    padding: 0px 20px 20px 30px;
    border-radius: 10px;
    background-color:  $color-tertiary;
}