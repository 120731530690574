* {
    box-sizing: border-box;
    font-family: "Fredoka", sans-serif;
    color: $color-text;
    font-weight: 400;
}

body {
    background-color : $color-primary ;
}

h2 {
    width: 70%;
    margin: auto;
    padding-bottom: 60px;
    text-align: center;
    font-weight: 400;
    font-size: 20px;
}

h1 {
    text-align: center;
}