.cardEvent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: auto;
    margin-bottom: 20px;
    padding: 15px;
    background-color: $color-tertiary;
    border-radius: 30px;
    height: 90px;
    border: 2px solid $color-textSpecial;
    &__link{
        text-decoration: none;
    }
    &__title {
        font-size: 20px;
        margin-right: 10px;
        overflow: hidden;
    }
    &__dates {
        font-size: 14px;
    }
}

.date {
    color: $color-textSpecial;
    margin: 5px;
}

