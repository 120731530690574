form {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    color: $color-text;
}

input {
    width: 90%;
    margin: auto;
    font-weight: 400;
    font-size: 29px;
    border: none;
    border-bottom: 2px groove $color-textSpecial;
    border-radius: 2px;
    outline: none;
    background-color: transparent;
    margin-bottom: 10px;
    color: $color-text;
    text-align: center;
    padding-bottom: 5px;
    &::placeholder {
        font-style: italic;
        color: $color-textSpecial;
    }
}

label {
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    padding-top: 15px;
    margin-bottom: 25px;
}

.form__submit {
    padding-top: 30px;
    text-align: center;
    padding: 10px;
    border-radius: 35px;
    background-color: $color-secondary;
    margin: auto;
    margin-top: 50px;
    border: 2px solid $color-tertiary;
    color: $color-text;
    cursor: pointer;
}

.form__title {
    color: $color-text;
    padding-bottom: 20px;
    font-weight: 400;
    font-size: 40px;
}

.accountForm__text {
    width: 90%;
    margin: auto;
    text-align: center;
    font-size: 20px;
    padding-bottom: 30px;
}

.switchForm {
    width: 90%;
    margin: auto;
    text-align: center;
    font-size: 20px;
    padding-top: 15px;
    &__link {
        color: $color-textSpecial;
        cursor: pointer;
    }
}